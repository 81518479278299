type MenuLink = {
  [linkName: string]: string;
};

const menuLinks: MenuLink = {
  home: '/',
  aboutUs: '/#about-us',
  howTo: '/#how-to',
  events: '/#events',
  education: '/knowledge',
  cryptocurrency: '/knowledge/cryptocurrency',
  mining: '/knowledge/mining',
  blockchain: '/knowledge/blockchain',
  educationPdfPreview: '/knowledge/pdf-preview',
  miningCity: 'https://me.miningcity.com/user-guide',
  signIn: 'https://me.miningcity.com/',
  signUp: 'https://me.miningcity.com/register',
  termsOfUse: '/terms-of-use',
  privacyPolicy: '/privacy-policy',
  quizBlockchain: '/knowledge/quiz-blockchain',
  quizMining: '/knowledge/quiz-mining',
  quizCryptocurrency: '/knowledge/quiz-cryptocurrency',
  quizWallet: '/knowledge/quiz-wallet',
  quizTrading: '/knowledge/quiz-trading',
  quizAltcoins: '/knowledge/quiz-altcoins',
  quizProofOfWork: '/knowledge/quiz-proof-of-work',
  quizMathBehindMining: '/knowledge/quiz-math-behind-mining',
  quizMiningPools: '/knowledge/quiz-mining-pools',
  quizPos: '/knowledge/quiz-pos',
  quizNft: '/knowledge/quiz-nft',
  quizEthereum: '/knowledge/quiz-ethereum',
  quizCoinsVsTokens: '/knowledge/quiz-coins-vs-tokens',
  quizTokenGovernance: '/knowledge/quiz-token-governance',
  quizShoppingWithCrypto: '/knowledge/quiz-shopping-with-crypto',
  quizResults: '/knowledge/quiz-results',
};

export default menuLinks;
